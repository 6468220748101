<template>
	<div class="grant">
		<div class="titleTop">
			<div class="right">
				<el-button type="primary" @click="kalmanShow = false">激活选中</el-button>
				<el-button type="warning" @click="kalmanShow = false">取消激活选中</el-button>
				<el-button type="primary" @click="kalmanShow = false">激活全部</el-button>
				<el-button type="warning" @click="kalmanShow = false">取消激活全部</el-button>
				<el-button type="primary" @click="kalmanShow = false">导出卡密</el-button>
			</div>
			<el-select v-model="marketInfo.b5b5" clearable placeholder="请选择用户分组">
				<el-option label="分组1" :value="1"></el-option>
			</el-select>
			<el-input placeholder="请输入关键字搜索" style="width: 200px;" v-model="addMealInfo.a4a4" class="input-with-select">
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>
		<en-table-layout
			:toolbar="false"
			:tableData="marketList"
			@selection-change="handleSelectionChange2">
			<template slot="table-columns">
				<el-table-column
					type="selection"
					width="55">
				</el-table-column>
				<el-table-column label="编号">
					<template slot-scope="scope">
						{{ scope.row.a6a6 }}
					</template>
				</el-table-column>
				<el-table-column label="卡号">
					<template slot-scope="scope">{{ scope.row.b6b6 }}</template>
				</el-table-column>
				<el-table-column label="卡密">
					<template slot-scope="scope">{{ scope.row.c6c6 }}</template>
				</el-table-column>
				<el-table-column label="套餐礼包">
					<template slot-scope="scope">{{ scope.row.c6c6 }}</template>
				</el-table-column>
				<el-table-column label="添加时间" width="160">
					<template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">{{ scope.row.c6c6 }}</template>
				</el-table-column>
				<el-table-column label="所属销售公司">
					<template slot-scope="scope">{{ scope.row.c6c6 }}</template>
				</el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="primary"
							@click="seeMeal(scope.row)">激活
						</el-button>
					</template>
				</el-table-column>
			</template>
			<el-pagination
				slot="pagination"
				v-if="pageData2"
				@size-change="handlePageSizeChange2"
				@current-change="handlePageCurrentChange2"
				:current-page="pageData2.page_no"
				:page-size="pageData2.page_size"
				:page-sizes="MixinPageSizes"
				:layout="MixinTableLayout"
				background
				:total="pageData2.data_total">
			</el-pagination>
		</en-table-layout>
	</div>
</template>

<script>
	// import * as API_HotGoods from '@/api/hotGoods'
	import UploadExcelComponent from "@/components/UploadExcel";
	import { handleDownload } from '@/utils'
	import Sortable from 'sortablejs'
	import { UE } from '@/components'
  import EnTableLayout from '../../../ui-components/TableLayout/src/main';
	export default {
		name: 'hotSellingGoods',
		components: {
      EnTableLayout,
			[UE.name]: UE,
			UploadExcelComponent
		},
		data() {
			return {
				downLoadType: 0,
				downLoadType1: 0,
				type: 1,
				// 2
				tableData: {},
				pageData: {},
				params: {},
				kalmanShow: false,
				onLineShow: false,
				kalmanType: 1,
				onLineType: 1,
				seeMealInfo: [],
				addMealShow: false,
				addMealInfo: {
					list: [
						{ d4d4: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' , e4e4: '商品1' },
						{ d4d4: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' , e4e4: '商品2商品1商品1商品1商品1商品1' },
						{ d4d4: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' , e4e4: '商品3' },
						{ d4d4: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' , e4e4: '商品4' },
					]
				},
				addMealRules: {
					b4b4: [
						{ required: true, message: '请输入生成数量', trigger: 'change' }
					],
				},

				marketShow: false,
				marketInfo: {
					a5a5: 1,
				},
				marketList: [],
				marketGoods: [],
				pageData1: {},
				pageData2: {},

				// 3
				steps2Info: {
					list: [
						{ a8a8: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' },
						{ a8a8: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' },
						{ a8a8: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' },
						{ a8a8: 'https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg' },
					]
				},
				btnShow: false,
				loading: false,
				loading1: false,
				tcPageShow: false,

				baseInfoForm: {
					/** 商品相册列表 */
					goods_gallery_list: [],

					/** 用来校验的商品相册 */
					goods_gallery: '',
				}
			}
		},
		mounted() {

		},
		methods: {
			/** 保存店铺设置*/
			nuxt(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						// API_Shop.saveShopSettings(_params).then(() => {
						// 	this.$message.success('保存店铺设置成功')
						// 	this.GET_ShopGradeData()
						// })

						this.steps = 1
					}
				})
			},
			handleSelectionChange(val){
				let ids = []
				val.forEach(item => {
					ids.push(item.goods_id)
				})
				this.idsList = ids
			},
			handleSelectionChange1(val){
				this.marketGoods = val
			},
			handleSelectionChange2(val){
				this.marketGoods = val
			},
			/** 分页大小发生改变 */
			handlePageSizeChange(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},
			/** 分页大小发生改变 */
			handlePageSizeChange1(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange1(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},
			/** 分页大小发生改变 */
			handlePageSizeChange2(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange2(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},
			/** 查看商品 */
			seeMeal(row) {
				this.kalmanShow = true
				this.seeMealInfo = row
			},
			/** 删除商品 */
			handleDeleteMeal(row) {
				this.$confirm('确认删除此礼包, 是否继续?', '提示', { type: 'warning' }).then(() => {
					const _ids = [row.goods_id].toString()
					API_goods.deleteGoods(_ids).then(() => {
						this.GET_GoodsList()
						this.$message.success('删除礼包成功！')
					})
				})
			},
			delGood(index){
				this.addMealInfo.list.splice(index, 1)
			},
			selectMarketGood(list){
				if(list.length){
					list = this.marketGoods
				}
				console.log(list)
			},
			handleShopLogoBefore(){
				this.loading = true
			},
			uploaded(response) {
				this.steps2Info.a7a7 = response.url
				this.loading = false
			},
			handleShopLogoBefore1(){
				this.loading1 = true
			},
			uploaded1(response) {
				this.steps2Info.a8a8 = response.url
				this.loading1 = false
			},
			delImg(key){
				let info = {...this.steps2Info}
				this.$set(info, key, '')
				this.steps2Info = info
			},
			/** 拖拽改变图片顺序 */
			setSort() {
				const el = document.querySelectorAll('div.avatar-uploader > ul.el-upload-list--picture-card')[0]
				this.sortable = Sortable.create(el, {
					ghostClass: 'sortable-ghost',
					setData: function(dataTransfer) { },
					onEnd: evt => {
						let temp = this.baseInfoForm.goods_gallery_list[evt.oldIndex]
						this.baseInfoForm.goods_gallery_list[evt.oldIndex] = this.baseInfoForm.goods_gallery_list[evt.newIndex]
						this.baseInfoForm.goods_gallery_list[evt.newIndex] = temp
					}
				})
			},
			/** 文件列表移除文件时的钩子  图片删除校验*/
			handleRemove(file, fileList) {
				this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
					if (key.img_id !== -1) {
						if (key.img_id === file.img_id) {
							this.baseInfoForm.goods_gallery_list.splice(index, 1)
						}
					} else {
						if (key.name === file.response.name) {
							this.baseInfoForm.goods_gallery_list.splice(index, 1)
						}
					}
				})
				if (fileList.length <= 0) {
					this.baseInfoForm.goods_gallery_list = []
					this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString()
				}
				// this.$refs.baseInfoForm.validateField('goods_gallery')
			},
			/** 文件列表上传成功时的钩子  上传成功校验 */
			handleSuccess(response, file, fileList) {
				this.baseInfoForm.goods_gallery_list.push({
					img_id: -1,

					original: response.url,

					url: response.url,

					sort: 0,

					name: response.name
				})
				this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString()
				// this.$refs['baseInfoForm'].validateField('goods_gallery')
				this.$nextTick(() => { this.setSort() })
			},
			downTemplate(){
				console.log(this.downLoadType)
				let tHeaders = ['卡密']
				let filterVals = ['yyyy']
				if(this.downLoadType){
					tHeaders = ['卡号', '卡密']
					filterVals = ['xxxx', 'yyyy']
				}
				handleDownload(this.integralImportData, tHeaders, filterVals, '卡密导入模板')
			},
			excelSuccess({ results }) {
				console.log(results)
			},

			downTemplate1(){
				console.log(this.downLoadType1)
				let tHeaders = ['手机号']
				let filterVals = ['qqqq']
				if(this.downLoadType1){
					tHeaders = ['工号', '密码']
					filterVals = ['wwww', 'eeee']
				}
				handleDownload(this.integralImportData, tHeaders, filterVals, '在线发放模板')
			},
			excelSuccess1({ results }) {
				console.log(results)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.grant{background: #fff;padding: 20px;border-radius: 5px;}
	.stepsBox{margin-top: 20px;}
	.dialog-footer{float: right;margin-top: 20px;}
	.tipBox{width: 440px;background: rgba(242, 242, 242, 1);border-radius: 6px;padding: 10px 20px;margin-bottom: 20px;}
	.tipBox p{line-height: 30px;font-size: 14px;color: #666;margin: 0;}
	.tipBox.b1 p{line-height: 24px;font-size: 12px;}
	.tipBox.b2{width: 550px;}
	.tipBox.b2 p{line-height: 24px;font-size: 12px;}
	.shep{margin-bottom: 15px;}

	.titleTop{margin-bottom: 10px;}
	.titleTop .right{float: right;}
</style>
